import * as React from 'react'
const THRESHOLD = 0

const useInfiniteScroll = (limit, callback) => {
  const nodeRef = React.useRef(null)
  const observerRef = React.useRef(null)
  React.useEffect(() => {
    if (!nodeRef.current) return
    observerRef.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          callback()
        }
      },
      { threshold: THRESHOLD }
    )
    observerRef.current.observe(nodeRef.current)
    return () => {
      observerRef.current.disconnect()
    }
  }, [limit, callback])
  return nodeRef
}

export default useInfiniteScroll
